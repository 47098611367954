import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Row, Col } from "react-bootstrap"
import ZigzagImage from "../components/zigzag-image"
import Helmet from "react-helmet"
import BackgroundImage from "gatsby-background-image"
import MarkdownContent from "./markdown"

const OrderCakeOnlineTemplate = ({
  title,
  seoTitle,
  banner,
  rows,
  description,
  metaDescription,
}) => (
  <Layout>
    <Seo title={seoTitle} />
    <Helmet>
      <html lang="en" />
      <title>{seoTitle}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
    <div className="text-center">
      <BackgroundImage
        Tag="section"
        className={"py-5"}
        fluid={banner}
        critical={true}
      >
        <div className="container">
          <h1 className={"text-white"}>{title}</h1>
          <p className={"text-white"}>{description}</p>
        </div>
      </BackgroundImage>
    </div>
    <div className={"light-bg py-5"}>
      <div className={"container montserrat zig-zags"}>
        {rows.map((row, i) => (
          <Row
            className={
              "align-items-center my-4 " +
              (i % 2 === 0 ? "flex-row-reverse" : "")
            }
          >
            <Col lg="4" className={"mb-4 mb-md-0"}>
              <ZigzagImage
                fluid={row.image.childImageSharp.fluid}
                alt="Birthday Cakes"
              />
            </Col>
            <Col lg="8">
              <h5>{row.title}</h5>
              <MarkdownContent content={row.description}></MarkdownContent>
              <p className="text-md-left text-center">
                <a
                  href={row.buttonURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={
                    "btn text-uppercase btn-primary btn-rounded fw-bold"
                  }
                >
                  {row.buttonText}
                </a>
              </p>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  </Layout>
)

OrderCakeOnlineTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metaDescription: PropTypes.string,
  banner: PropTypes.object,
  rows: PropTypes.array,
  seoTitle: PropTypes.string,
}

export default OrderCakeOnlineTemplate
