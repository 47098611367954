import React from "react"
import { graphql, StaticQuery } from "gatsby"
import OrderCakeOnlineTemplate from '../components/order-cake-online-template'

const SendGiftTemplate = () => (
      <StaticQuery
        query={graphql `
        query GiftingPageQuery {
          markdownRemark(
            frontmatter: { templateKey: { eq: "gifting" } }
          ) {
            id
            frontmatter {
              banner {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              rows {
                image {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                title
                description
                buttonURL
                buttonText
              }
              title
              seoTitle
              description
              metaDescription
            }
          }
        }
      `}
      render={data => {
        const mData = data.markdownRemark.frontmatter
        const imageData = mData.banner.childImageSharp.fluid
        return (
          <OrderCakeOnlineTemplate
            title={mData.title}
            seoTitle={mData.seoTitle}
            banner={imageData}
            rows={mData.rows}
            description={mData.description}
            metaDescription={mData.metaDescription}
          />
        )
      }} />
)

const SendGiftPage = () => {
  return <SendGiftTemplate />
}

export default SendGiftPage
